//colors
$black: #000;
$black_70: rgba(0, 0, 0, .7);
$mine_shaft: #333;
$charcoal: #484747;
$charcoal_light: #646464;
$mine_shaft_60: rgba(50, 50, 50, .6);
$stone_60: rgba(102, 102, 102, .6);
$stone: #6d665d;
$grey_dark: #c9c9c9;
$grey: #dadad9;
$grey_light: #e8e8e8;
$white: #fff;
$white_50: rgba(255, 255, 255, .5);
$duckegg: #36a7a4;
$btn_blue: #006ac7;
$legion_blue: #144182;
$navy: #0e2143;
$bgLightBlue: #39A6E1;
$bgSilver: #F0F0F0;
$red: #e00a0f;

$rbl-dark_blue: #0058A5;

//colors
$black: #000;
$black_70: rgba(0, 0, 0, .7);
$mine_shaft: #333;
$charcoal: #484747;
$charcoal_light: #646464;
$mine_shaft_60: rgba(50, 50, 50, .6);
$stone_60: rgba(102, 102, 102, .6);
$stone: #6d665d;
$grey_dark: #c9c9c9;
$grey: #dadad9;
$grey_light: #e8e8e8;
$white_50: rgba(255, 255, 255, .5);
$btn_blue: #006ac7;
$legion_blue: #144182;

//fonts
$icon: "Font Awesome 5 Pro";
$futura: 'futura-pt', 'arial', 'sans-serif';
$arial: 'arial', 'sans-serif';