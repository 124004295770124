.navigation {
	width: 300px;
	position: fixed;
	left: 0;
	// top: 150px;
	padding-top: 148px;
	height:100%;
	overflow:hidden;
	z-index: 9999;
	text-align:left;
	transition: left 0.3s;
	&.expanded {
		width:564px;
		padding-right:264px;
		color: $white;
	}
	&.hidenav {
		left:-300px;
		transition:left 0.3s;
		&:before {
			left:-300px;
			transition:left 0.3s;
		}
		&.open {
			left:0;
			&:before {
				left:0;
			}
		}

	}
	.nav-area {
		width:10000px;
		padding-right:20px;
		overflow: auto;
		max-height:100%;
		min-height: 456px;
		
		#nav {
			width:300px;
		}
	}
	&:before {
		position:relative;
		left:0;
		top:150px;
		// height:100%;
		width:44px;
		background: $rbl-dark_blue;
		z-index:1;
		content:"";
		display: block;
	}
	&:after {
		position:relative;
		left:0;
		top:0;
		// height:100%;
		width:300px;
		background: $white;
		z-index:0;
		content:"";
		display: block;
	}
	a {
		pointer-events: auto;
	}
	.drop {
		padding: 125px 0 0;
		width: 236px;
		position: relative;
		left: -236px;
		transition: all .25s linear;
	}
	ul {
		margin:0;
		padding:0;
		position:relative;
		z-index:2;
		&.first-level {
			min-height: 456px;
			background: $rbl-dark_blue;
		}
		li {
			list-style: none;
			display: block;
			font-size:19px;
			color:$black;
			overflow:visible;
			ul.sub-nav {
				display:none;
				position:fixed;
				left:300px;
				top:148px;
				min-height:100%;
				background:$rbl-dark_blue;
				text-align: left;
				width:250px;
				position:fixed;
				&.previous {
					top:176px;
				}
				&.full-height {
					top:0;
					height:100%;
				}
				li {
					a {
						font-size: 19px;
						padding:10px 20px;
						&:hover {
							background:$white;
							color:$rbl-dark_blue;
						}
					}
				}
			}
		}
	}
}
.sub-level-nav {
	position:relative;
	background:$rbl-dark_blue;
	color:$white;
	overflow-y:hidden;
	.nav-item {
		a {
			color:$white;
			&:hover {
				background:$white;
				color:$rbl-dark-blue;
				a {
					color:$rbl-dark-blue;
				}
			}
		}

	}
}

.nav-item {
	a {
		display: block;
		min-height:44px;
		line-height: 24px;
		padding:10px 25px 10px 54px;
		color:$white;
		position:relative;
		&:before {
			display: block;
			font-family: "Font Awesome 5 Pro";
			position:absolute;
			left:0;
			top:0;
			width:44px;
			height:44px;
			padding:10px 0;
			text-align: center;
			font-weight:900;
		}
	}
	&.previous {
		a {		
			background:$charcoal_light;
			color:$white;
			&:before {
				background: $charcoal;
				content:"\f0d9";
			}
		}
		&:hover {
			a {
				color:$rbl-dark_blue;
				background:$white;
				&:before {
					color: $white;
					background:$rbl-dark_blue;
				}
			}
		}
	}
	&.active {
		> a {
			background:$white;
			color:$rbl-dark-blue;
		}
		.has-children {
			color:$white;
		}
	}

	&.join-the-legion {
		background: $rbl-dark-blue;
		// padding: 20px 15px;
		a {
			padding: 0.55em 1.5em;
			margin-left:44px;
			line-height: 20px;
		}
	}
	.has-children {
		position:absolute;
		width:25px;
		height:44px;
		top:0;
		right:0;
		color:$white;
		padding:10px 0;
		line-height: 24px;
	}
	&:hover {		
		.has-children {
			color:$rbl-dark-blue;
		}
		& > ul.sub-nav {
			display: block;
		}
	}
	&.back {
		background: $charcoal_light;

		a:before {		
			content:"\f0d9";
			background: $charcoal;
		}
	}
	&.home a:before {		
		content:"\f015";
	}
	&.contact a:before {		
		content:"\f095";
	}
	&.find-us a:before {		
		content:"\f041";
	}
	&.shop a {
		&:hover {
			background:$btn_blue;
			color:$white;
		}
		&:before {		
			content:"\f07a";
			background:$btn_blue;
			color:$white;
		}
	}
	&.donate a {
		&:hover {
			background:$red;
			color:$white;
		}
		&:before {		
			content:"\f004";
			background:$red;
			color:$white;
		}
	}
	}


.sub-nav {
	.nav-item {
		a:hover {
			background:$white;
			color:$rbl-dark-blue;
			a {
				color:$rbl-dark-blue;
			}
		}
	}
}


.nav-opener {
	display:none;
	span, &:before, &:after {
		position: absolute;
		left: 11px;
		right: 11px;
		width: 24px;
		height: 2px;
		top: 22px;
		background: $navy;
		transition: all .25s linear;
		transform:rotate(0);
		transition-delay: 0.3s;
	}
	&:after {
		content: "";
		top: 15px;
	}
	&:before {
		content: "";
		top: 29px;
	}
	&.open {
		left:300px;
		transition: all .25s linear;
		span {
			opacity:0;
		}
		&:before {
			transform:rotate(-45deg);
			top: 22px;
		}
		&:after {
			transform:rotate(45deg);
			top: 22px;
		}
	}
}

#wrapper {
	&.showNavBurger {
		.nav-opener {
			position: fixed;
			left: 0;
			top: 144px;
			width: 44px;
			height: 44px;
			background: $bgSilver;
			padding: 11px;
			z-index: 9999;
			display: block;
			transition:all 0.3s;
			&.open {
				left:300px;
			}
		}
	
	}
}
.nav-active {
	.drop {
		left: 0;
	}
	.nav-area {
		width: 236px;
		overflow: auto;
		overflow-x: hidden;
	}
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1170px) {
	.navigation {
		left:-300px;
		transition:left 0.3s;
		&:before {
			left:-300px;
			transition:left 0.3s;
		}
		&.open {
			left:0;
			&:before {
				left:0;
			}
		}
	}
	.nav-opener {
	    position: fixed;
	    left: 0;
	    top: 114px;
	    width: 44px;
	    height: 44px;
	    background: #fff;
	    padding: 11px;
	    z-index: 9999;
	    display: block;
	    transition:all 0.3s;
	    &.open {
	    	left:300px;
	    }
	}
	.nav-item {
		.has-children {
			display: none;
		}
		&:hover {
			& > ul.sub-nav {
				display: none;
			}
		}
	}		
}

@media only screen and (min-width : 1080px) {
	.nav-opener {
		top: 150px;
	}

}


/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
}


@media only screen and (max-width: 680px) {
	.nav-opener {
		top: 95px;
	}
	.join-the-legion {
		.a-button {
			width: auto;
		}
	}
}