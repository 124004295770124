.select2 {
	width:100% !important;
	margin-bottom:30px;
}

.select2-container--default {
		border-radius:0;
		border:0;
		line-height: 44px;
	.select2-selection--single {
		border-radius:0;
		border:0;
		background:$grey;
		height:44px;
		line-height: 44px;
		.select2-selection__rendered {
			line-height:44px;
			color:$black;
			padding-right:44px;
			padding-left:15px;
		}
		span {
			height:44px;
		}
		span:empty:before {
			color:$black;
		}
		.select2-selection__arrow {
			background:$red;
			color:$white;
			width:44px;
			height:44px;
			text-align:center;
			line-height: 44px;
			top:0;
			right:0;
			&:after{

			font-family:"Font Awesome 5 Pro";
			content:"\f107";
			font-weight:900;
			}
			b {
				display:none;
			}
		}
	}
	&.select2-container--open {
		.select2-selection--single {
			.select2-selection__arrow {
				&:after{
				content:"\f106";
				}
			}
		}
	}
}

.select2-results__options {
	background:$grey_light;
}

.select2-results__option {
	padding:15px 0;
	min-height:44px;
	line-height:24px;
	padding:10px 15px;
}


/* Medium Devices, Desktops */
@media only screen and (max-width : 1170px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
}
