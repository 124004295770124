#wrapper {
	&.nav-active {
		.hold {
			padding: 0;
			margin: 0 0 0 236px;
		}
		#header {
			margin-left: -46px;
		}
		.logo {
			right: -236px;
			transition: all .25s linear;
		}
	}
}

#header {
	height: 0;
	background: $white;
	transition: all .25s linear;
	position:absolute;
	z-index:999;
	left:0;
	right:0;
	top:0;
	width:100%;
}
.header-btns {
	display:none;
	margin:0;
	padding:0 0 0 44px;
	li {
		list-style: none;
		display:inline-block;
		text-align: center;
		line-height: 24px;
		float:left;

		a {
			display: inline-block;
			color:$white;
			padding:10px;
			height:44px;
			min-width: 44px;
			&.donate {
				background:$red;
			}
			&.shop {
				background:$btn_blue;
			}
		}
	}
}

.hold {
	overflow: hidden;
	width: 100%;
	// height: 100%;
	display: table;
	table-layout: fixed;
	transition: all .25s linear;
	background: $white;
}
// .logo {
// 	position: absolute;
// 	right: 35px;
// 	top: 0;
// 	z-index: 9998;
// 	max-width: 110px;
// 	transition: all .25s linear;
// 	a img {
// 		display: block;
// 		width: 100%;
// 		height: auto;
// 	}
// }



header {
	&.header {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		// height: 150px;
		background-color: #CEE0EF;
		&__logo {
			img {
				height: 6rem;
				vertical-align: text-bottom;
				width: auto;
			}
		}
	}
	&.branches-header {
		width:100%;
		position:fixed;
		z-index: 999999;
	}
	&.header__logo img, &.header {
		&__mobile-ctas, &__toggle, .__navigation__item 
		{
			position: relative;
			z-index: 11;
		}
	}
	.o-header__container {
		a {
			color: $rbl-dark_blue;
			&.o-header__cta--donate {
				color: $white;
			}
		}
		.o-header__navigation[data-toggle-state=opened] {
			a {
			color: $white;
			}
		}

	}
}
@media (min-width: 1080px) {

	.header__logo img {
		height: 10rem;
	}
}


/* Medium Devices, Desktops */
@media only screen and (max-width : 1170px) {
	#header {
		height:44px;
	}


}

@media (min-width: 680px) {
	.o-header__logo img {
		height: 8rem;
	}
}
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	#header {
		position:fixed;
	}
	
	.logo {
		max-width: 65px;
	}	
}
