.breadcrumb ul:after, .search-holder:after, #main:after, #header:after {
  content: " ";
  display: block;
  clear: both;
}

.header-part .sub-nav ul, .breadcrumb ul, .socials-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

sup, sub {
  font-size: 75%;
  position: relative;
  vertical-align: baseline;
}

html {
  height: 100%;
  box-sizing: border-box;
}
html.no-footer-bottom {
  height: auto;
}

* {
  box-sizing: inherit;
  max-height: 1000000px;
}
*:before {
  box-sizing: inherit;
}
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  color: #000;
  background: #fff;
  min-width: 320px;
}

article, aside, details, figcaption, figure, footer, header, main, nav, section, summary {
  display: block;
}
article.content-box h2, aside.content-box h2, details.content-box h2, figcaption.content-box h2, figure.content-box h2, footer.content-box h2, header.content-box h2, main.content-box h2, nav.content-box h2, section.content-box h2, summary.content-box h2 {
  margin-bottom: 10px;
}

footer a {
  color: #0058A5;
}

.clearfix {
  clear: both;
}

img {
  border-style: none;
  max-width: 100%;
  height: auto;
  max-height: none;
}

a {
  text-decoration: none;
  color: #e00a0f;
}
a:hover {
  text-decoration: none;
}

input[type=search] {
  max-width: 720px;
}

input:not([type=radio]):not([type=checkbox]):not([type=search]), textarea, select {
  vertical-align: middle;
  width: 100%;
  -webkit-appearance: none;
}
input:not([type=radio]):not([type=checkbox]):not([type=search]):not(#surprise_amountb, #surprise_amounta, .btn, .link, .a-select, .a-input), textarea:not(#surprise_amountb, #surprise_amounta, .btn, .link, .a-select, .a-input), select:not(#surprise_amountb, #surprise_amounta, .btn, .link, .a-select, .a-input) {
  color: #000 !important;
}
input:not([type=radio]):not([type=checkbox]):not([type=search]).gsc-search-button, textarea.gsc-search-button, select.gsc-search-button {
  color: #fff;
  background: #e00a0f;
  border: 0;
}

label {
  display: inline-block;
  line-height: 24px;
  padding-top: 10px;
}

form, fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
  padding: 0;
  border: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

input[type=button], input[type=reset], input[type=file], input[type=submit] {
  cursor: pointer;
}

input[type=text], input[type=tel], input[type=email], input[type=url], input[type=password], input[type=number], input[type=phone], input[type=date] {
  margin: 0;
  padding: 0 15px;
  height: 44px;
  line-height: 44px;
  width: 100%;
  margin-bottom: 30px;
}
input[type=text]:focus, input[type=tel]:focus, input[type=email]:focus, input[type=url]:focus, input[type=password]:focus, input[type=number]:focus, input[type=phone]:focus, input[type=date]:focus {
  border-color: #6d665d;
}

.icheck-item {
  margin-bottom: 20px !important;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button, input[type=date]::-webkit-inner-spin-button, input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input#recaptcha_response_field {
  height: auto;
  line-height: initial;
  background: #fff;
  padding: 0 5px;
}

.field-validation-error {
  display: block;
  margin-bottom: 5px;
  color: #e00a0f;
}

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top;
  margin-bottom: 30px;
  padding: 10px 15px;
}
textarea:focus {
  border-color: #6d665d;
}

input[type=checkbox], input[type=radio] {
  padding: 0;
}

#postcode_lookup_field button, #postcode_lookup_field select {
  margin-bottom: 30px;
}
#postcode_lookup_field button {
  max-width: 273px;
}

h1, h2, h3, h4, h5, h6 {
  color: #0058A5;
}

h4 {
  font-weight: 600;
}

p {
  margin: 0 0 1.2em;
}

q {
  quotes: none;
}
q:before {
  content: "";
  content: none;
}
q:after {
  content: "";
  content: none;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
table thead th {
  text-align: left;
  border-bottom: 2px solid #dadad9;
  padding: 15px;
}
table tbody td {
  text-align: left;
  padding: 15px;
}

i {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
}

i span {
  overflow: hidden;
  display: block;
  width: 0;
  height: 0;
}

blockquote {
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  margin-top: 0;
}

input, textarea, select {
  background: #dadad9;
  border: 0;
  color: #000;
}

xmp {
  white-space: pre-wrap;
}

.spacer {
  display: block;
  clear: both;
  float: none;
  height: 30px;
}

.recaptchatable #recaptcha_response_field {
  border: 0;
}

.error {
  border: solid 1px #e00a0f;
  padding: 15px;
  color: #e00a0f;
  background: rgba(224, 10, 15, 0.2);
}

#main {
  background: #fff;
  overflow: hidden;
  padding-top: 148px;
}

.container-fluid, .container, .sub-container {
  max-width: 940px;
  margin: 0 auto;
}

.mob-blackout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: #000;
  display: none;
  opacity: 0.7;
}

.link, button.link, button {
  color: #fff;
  display: block;
  background: #e00a0f;
  padding: 10px 5px;
  margin: 0 0 3px;
  text-align: center;
  border-radius: 3px;
  width: 100%;
  border: 0;
  font-size: 18px;
  line-height: 24px;
  font-family: "futura-pt", "arial", "sans-serif";
  transition: all 0.2s;
}

.main-home {
  border-top: 0 !important;
}

#wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
#wrapper.main-wrapper {
  padding-left: 300px;
}

.icheckbox_square-red, .iradio_square-red {
  height: 27px !important;
  margin-right: 10px !important;
  position: relative;
  top: 10px;
}

.center {
  text-align: center;
}

.module, .block {
  margin: 0 0 40px;
}
.module:after, .block:after {
  content: "";
  clear: both;
  visibility: hidden;
  display: block;
}

iframe {
  display: block;
  margin: 0 auto;
}

.img-strip {
  position: relative;
  max-height: 500px;
  overflow: hidden;
}
.img-strip .img-holder {
  overflow: hidden;
}
.img-strip .img-holder img {
  min-width: 100%;
  max-height: none;
}
.img-strip .person-info {
  color: #fff;
}

.btn-default {
  position: relative;
  color: #000;
  overflow: hidden;
  display: inline-block;
  min-width: 116px;
  padding: 0 64px 0 20px;
  background: #dadad9;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 44px;
  line-height: 44px;
  border-radius: 2px;
  border: 0;
  max-width: 100%;
  transition: background 0.2s;
}
.btn-default:before {
  content: "\f105";
  position: absolute;
  right: 0;
  top: 0;
  font-family: "Font Awesome 5 Pro";
  color: #fff;
  background: #e00a0f;
  display: block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 22px;
  text-align: center;
  font-weight: 900;
}
.btn-default.back {
  padding: 0 0 0 44px;
}
.btn-default.back:before {
  content: "\e809";
  right: auto;
  left: 0;
}

.hidden {
  display: none;
}

h2.heading {
  text-align: center;
  margin: 0 0 30px;
  display: block;
  padding: 0 15px;
  overflow: hidden;
  text-transform: uppercase;
  margin: 0 0 30px 0;
}
h2.heading span {
  position: relative;
  background: transparent;
  padding: 0 15px;
  display: inline-block;
  vertical-align: top;
}
h2.heading span br {
  display: none;
}
h2.heading span:before, h2.heading span:after {
  content: "";
  background: #6d665d;
  height: 1px;
  width: 9999px;
  position: absolute;
  right: 100%;
  top: 50%;
}
h2.heading span:after {
  right: auto;
  left: 100%;
}

.grid-item-link {
  text-align: center;
  color: #000;
  padding-bottom: 30px;
  display: block;
}
.grid-item-link span:not(.logo-container) {
  display: block;
  line-height: 30px;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 500;
}
.grid-item-link img {
  margin-bottom: 5px;
  width: 100%;
}
.grid-item-link:hover span:after {
  opacity: 0.75;
}

.grid-title {
  text-transform: uppercase;
  font-weight: 500;
}
.grid-title:after {
  display: inline-block;
  margin-left: 10px;
  content: "\f105";
  color: #fff;
  left: 0;
  background: #e00a0f;
  -webkit-transition: opacity 0.2s;
  transition: left 0.2s;
  position: relative;
  border-radius: 1px;
  font-size: 18px;
  width: 26px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  line-height: 26px;
}

.grid-date {
  color: #6d665d;
}

.head-part {
  padding: 40px 0;
}
.head-part.add {
  padding-top: 0;
}
.head-part.add .breadcrumb {
  background: none;
}

.header {
  padding-bottom: 25px;
}
.header p {
  margin: 0;
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1170px) {
  #main {
    padding-top: 44px;
  }

  .header-btns {
    display: block;
  }

  #wrapper.main-wrapper {
    padding-left: 0;
    transition: padding-left 0.3s;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .img-strip {
    max-height: 200px;
  }

  input, textarea, select, .select2 {
    margin-bottom: 15px;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .block-holder .masonry-col, .block-holder .col {
    width: 100%;
  }

  .navigation {
    overflow: auto;
  }
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  .navigation {
    width: 260px;
  }

  .nav-opener.open {
    left: 260px;
  }
}
@media print {
  /* All your print styles go here */
  #header, #footer, .navigation {
    display: none !important;
  }

  #wrapper.main-wrapper {
    padding-left: 0 !important;
  }

  .container-fluid {
    width: 100% !important;
    max-width: 100% !important;
  }
}
#wrapper.nav-active .hold {
  padding: 0;
  margin: 0 0 0 236px;
}
#wrapper.nav-active #header {
  margin-left: -46px;
}
#wrapper.nav-active .logo {
  right: -236px;
  transition: all 0.25s linear;
}

#header {
  height: 0;
  background: #fff;
  transition: all 0.25s linear;
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.header-btns {
  display: none;
  margin: 0;
  padding: 0 0 0 44px;
}
.header-btns li {
  list-style: none;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  float: left;
}
.header-btns li a {
  display: inline-block;
  color: #fff;
  padding: 10px;
  height: 44px;
  min-width: 44px;
}
.header-btns li a.donate {
  background: #e00a0f;
}
.header-btns li a.shop {
  background: #006ac7;
}

.hold {
  overflow: hidden;
  width: 100%;
  display: table;
  table-layout: fixed;
  transition: all 0.25s linear;
  background: #fff;
}

header.header {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #CEE0EF;
}
header.header__logo img {
  height: 6rem;
  vertical-align: text-bottom;
  width: auto;
}
header.branches-header {
  width: 100%;
  position: fixed;
  z-index: 999999;
}
header.header__logo img__mobile-ctas, header.header__logo img__toggle, header.header__logo img .__navigation__item, header.header__mobile-ctas, header.header__toggle, header.header .__navigation__item {
  position: relative;
  z-index: 11;
}
header .o-header__container a {
  color: #0058A5;
}
header .o-header__container a.o-header__cta--donate {
  color: #fff;
}
header .o-header__container .o-header__navigation[data-toggle-state=opened] a {
  color: #fff;
}

@media (min-width: 1080px) {
  .header__logo img {
    height: 10rem;
  }
}
/* Medium Devices, Desktops */
@media only screen and (max-width: 1170px) {
  #header {
    height: 44px;
  }
}
@media (min-width: 680px) {
  .o-header__logo img {
    height: 8rem;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  #header {
    position: fixed;
  }

  .logo {
    max-width: 65px;
  }
}
.navigation {
  width: 300px;
  position: fixed;
  left: 0;
  padding-top: 148px;
  height: 100%;
  overflow: hidden;
  z-index: 9999;
  text-align: left;
  transition: left 0.3s;
}
.navigation.expanded {
  width: 564px;
  padding-right: 264px;
  color: #fff;
}
.navigation.hidenav {
  left: -300px;
  transition: left 0.3s;
}
.navigation.hidenav:before {
  left: -300px;
  transition: left 0.3s;
}
.navigation.hidenav.open {
  left: 0;
}
.navigation.hidenav.open:before {
  left: 0;
}
.navigation .nav-area {
  width: 10000px;
  padding-right: 20px;
  overflow: auto;
  max-height: 100%;
  min-height: 456px;
}
.navigation .nav-area #nav {
  width: 300px;
}
.navigation:before {
  position: relative;
  left: 0;
  top: 150px;
  width: 44px;
  background: #0058A5;
  z-index: 1;
  content: "";
  display: block;
}
.navigation:after {
  position: relative;
  left: 0;
  top: 0;
  width: 300px;
  background: #fff;
  z-index: 0;
  content: "";
  display: block;
}
.navigation a {
  pointer-events: auto;
}
.navigation .drop {
  padding: 125px 0 0;
  width: 236px;
  position: relative;
  left: -236px;
  transition: all 0.25s linear;
}
.navigation ul {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;
}
.navigation ul.first-level {
  min-height: 456px;
  background: #0058A5;
}
.navigation ul li {
  list-style: none;
  display: block;
  font-size: 19px;
  color: #000;
  overflow: visible;
}
.navigation ul li ul.sub-nav {
  display: none;
  position: fixed;
  left: 300px;
  top: 148px;
  min-height: 100%;
  background: #0058A5;
  text-align: left;
  width: 250px;
  position: fixed;
}
.navigation ul li ul.sub-nav.previous {
  top: 176px;
}
.navigation ul li ul.sub-nav.full-height {
  top: 0;
  height: 100%;
}
.navigation ul li ul.sub-nav li a {
  font-size: 19px;
  padding: 10px 20px;
}
.navigation ul li ul.sub-nav li a:hover {
  background: #fff;
  color: #0058A5;
}

.sub-level-nav {
  position: relative;
  background: #0058A5;
  color: #fff;
  overflow-y: hidden;
}
.sub-level-nav .nav-item a {
  color: #fff;
}
.sub-level-nav .nav-item a:hover {
  background: #fff;
  color: #0058A5;
}
.sub-level-nav .nav-item a:hover a {
  color: #0058A5;
}

.nav-item a {
  display: block;
  min-height: 44px;
  line-height: 24px;
  padding: 10px 25px 10px 54px;
  color: #fff;
  position: relative;
}
.nav-item a:before {
  display: block;
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: 0;
  top: 0;
  width: 44px;
  height: 44px;
  padding: 10px 0;
  text-align: center;
  font-weight: 900;
}
.nav-item.previous a {
  background: #646464;
  color: #fff;
}
.nav-item.previous a:before {
  background: #484747;
  content: "\f0d9";
}
.nav-item.previous:hover a {
  color: #0058A5;
  background: #fff;
}
.nav-item.previous:hover a:before {
  color: #fff;
  background: #0058A5;
}
.nav-item.active > a {
  background: #fff;
  color: #0058A5;
}
.nav-item.active .has-children {
  color: #fff;
}
.nav-item.join-the-legion {
  background: #0058A5;
}
.nav-item.join-the-legion a {
  padding: 0.55em 1.5em;
  margin-left: 44px;
  line-height: 20px;
}
.nav-item .has-children {
  position: absolute;
  width: 25px;
  height: 44px;
  top: 0;
  right: 0;
  color: #fff;
  padding: 10px 0;
  line-height: 24px;
}
.nav-item:hover .has-children {
  color: #0058A5;
}
.nav-item:hover > ul.sub-nav {
  display: block;
}
.nav-item.back {
  background: #646464;
}
.nav-item.back a:before {
  content: "\f0d9";
  background: #484747;
}
.nav-item.home a:before {
  content: "\f015";
}
.nav-item.contact a:before {
  content: "\f095";
}
.nav-item.find-us a:before {
  content: "\f041";
}
.nav-item.shop a:hover {
  background: #006ac7;
  color: #fff;
}
.nav-item.shop a:before {
  content: "\f07a";
  background: #006ac7;
  color: #fff;
}
.nav-item.donate a:hover {
  background: #e00a0f;
  color: #fff;
}
.nav-item.donate a:before {
  content: "\f004";
  background: #e00a0f;
  color: #fff;
}

.sub-nav .nav-item a:hover {
  background: #fff;
  color: #0058A5;
}
.sub-nav .nav-item a:hover a {
  color: #0058A5;
}

.nav-opener {
  display: none;
}
.nav-opener span, .nav-opener:before, .nav-opener:after {
  position: absolute;
  left: 11px;
  right: 11px;
  width: 24px;
  height: 2px;
  top: 22px;
  background: #0e2143;
  transition: all 0.25s linear;
  transform: rotate(0);
  transition-delay: 0.3s;
}
.nav-opener:after {
  content: "";
  top: 15px;
}
.nav-opener:before {
  content: "";
  top: 29px;
}
.nav-opener.open {
  left: 300px;
  transition: all 0.25s linear;
}
.nav-opener.open span {
  opacity: 0;
}
.nav-opener.open:before {
  transform: rotate(-45deg);
  top: 22px;
}
.nav-opener.open:after {
  transform: rotate(45deg);
  top: 22px;
}

#wrapper.showNavBurger .nav-opener {
  position: fixed;
  left: 0;
  top: 144px;
  width: 44px;
  height: 44px;
  background: #F0F0F0;
  padding: 11px;
  z-index: 9999;
  display: block;
  transition: all 0.3s;
}
#wrapper.showNavBurger .nav-opener.open {
  left: 300px;
}

.nav-active .drop {
  left: 0;
}
.nav-active .nav-area {
  width: 236px;
  overflow: auto;
  overflow-x: hidden;
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1170px) {
  .navigation {
    left: -300px;
    transition: left 0.3s;
  }
  .navigation:before {
    left: -300px;
    transition: left 0.3s;
  }
  .navigation.open {
    left: 0;
  }
  .navigation.open:before {
    left: 0;
  }

  .nav-opener {
    position: fixed;
    left: 0;
    top: 114px;
    width: 44px;
    height: 44px;
    background: #fff;
    padding: 11px;
    z-index: 9999;
    display: block;
    transition: all 0.3s;
  }
  .nav-opener.open {
    left: 300px;
  }

  .nav-item .has-children {
    display: none;
  }
  .nav-item:hover > ul.sub-nav {
    display: none;
  }
}
@media only screen and (min-width: 1080px) {
  .nav-opener {
    top: 150px;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 680px) {
  .nav-opener {
    top: 95px;
  }

  .join-the-legion .a-button {
    width: auto;
  }
}
.select2 {
  width: 100% !important;
  margin-bottom: 30px;
}

.select2-container--default {
  border-radius: 0;
  border: 0;
  line-height: 44px;
}
.select2-container--default .select2-selection--single {
  border-radius: 0;
  border: 0;
  background: #dadad9;
  height: 44px;
  line-height: 44px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 44px;
  color: #000;
  padding-right: 44px;
  padding-left: 15px;
}
.select2-container--default .select2-selection--single span {
  height: 44px;
}
.select2-container--default .select2-selection--single span:empty:before {
  color: #000;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  background: #e00a0f;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  top: 0;
  right: 0;
}
.select2-container--default .select2-selection--single .select2-selection__arrow:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f107";
  font-weight: 900;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow:after {
  content: "\f106";
}

.select2-results__options {
  background: #e8e8e8;
}

.select2-results__option {
  padding: 15px 0;
  min-height: 44px;
  line-height: 24px;
  padding: 10px 15px;
}

/* Medium Devices, Desktops */
/* Small Devices, Tablets */
#footer {
  width: 100%;
  background: #fff;
  padding-top: 20px;
}

.footer-logo {
  max-width: 120px;
  max-height: 60px;
  margin: 0 auto 20px auto;
}

.no-footer-bottom body {
  height: auto;
}
.no-footer-bottom #wrapper {
  height: auto;
  overflow: hidden;
}
.no-footer-bottom #footer {
  display: block;
  height: auto;
}

.socials {
  text-align: center;
}
.socials .live-on {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
}
.socials .live-on .title {
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top;
  margin: -5px 0 0;
  font-size: 40px;
  font-weight: 400;
  line-height: 36px;
}
.socials .live-on .legion-logo {
  padding: 0 0 0 5px;
  display: inline-block;
  vertical-align: top;
}

.socials-holder {
  overflow: hidden;
  margin: 0 0 45px;
}

.socials-list {
  padding: 0 5px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.socials-list:before {
  right: auto;
  left: 100%;
}
.socials-list li {
  display: inline-block;
  vertical-align: top;
  padding: 0 7px;
}
.socials-list li a {
  color: #fff;
  display: block;
  font-size: 20px;
  line-height: 44px;
  height: 44px;
  width: 44px;
  background: #484747;
  border-radius: 2px;
  position: relative;
}
.socials-list li a:hover {
  opacity: 0.8;
}
.socials-list li a i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.join-the-legion {
  background: #333;
  padding: 15px;
  text-align: center;
}

.copyright {
  background: #484747;
  color: #fff;
  text-align: center;
  padding: 30px;
  font-size: 14px;
  line-height: 24px;
}
.copyright .tel {
  display: block;
  margin-bottom: 15px;
}
.copyright p {
  margin: 0;
}
.copyright a {
  color: #fff;
}
.copyright a:hover {
  opacity: 0.75;
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1170px) {
  .socials-list li {
    margin-bottom: 15px;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .footer-logo {
    margin: 0 auto 10px;
  }
}
.img-strip {
  position: relative;
  margin-bottom: 40px;
}
.img-strip img {
  width: 100%;
}
.img-strip h2 {
  position: absolute;
  top: 45px;
  left: 0;
  line-height: 24px;
  padding: 10px;
  font-size: 3rem;
  font-weight: 600;
  color: #0058A5;
  padding-left: 80px;
  display: none;
}

.content-box {
  width: 100%;
  margin: 0 auto;
  padding: 0 80px;
}

.gMap {
  margin: 0 auto;
}

.related-links-container {
  padding: 30px 0;
  border: solid 1px #6d665d;
  border-width: 1px 0;
}
.related-links-container ul {
  padding: 0;
}
.related-links-container ul li {
  list-style: none;
  display: block;
  margin-bottom: 15px;
}

ul.ticker {
  display: none;
  /* &:first-of-type {
  	display: block;
  } */
}

.gallery-thumb {
  float: left;
  text-align: center;
  width: 170px;
  margin: 0 15px 10px 0;
}

@media only screen and (min-width: 680px) {
  .img-strip h2 {
    font-size: 3rem;
    top: 60px;
  }
}
@media only screen and (min-width: 768px) {
  .img-strip img {
    float: left;
    position: static;
    margin: 0;
  }
  .img-strip h2 {
    top: 90px;
    display: block;
  }
}
@media only screen and (min-width: 1080px) {
  .img-strip h2 {
    top: 130px;
    font-size: 4.2rem;
  }
}
@media only screen and (min-width: 1170px) {
  .img-strip h2 {
    font-size: 6.4rem;
    top: 90px;
  }
}
.heading-info {
  text-align: center;
  padding-top: 20px;
}
.heading-info h1 {
  font-size: 44px;
  line-height: 52px;
}
.heading-info h1 span {
  display: block;
  font-size: 36px;
  line-height: 40px;
}
.heading-info p {
  margin-bottom: 0;
}

.search-holder {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.search-holder button[type=submit] {
  color: #fff;
  float: right;
  width: 44px;
  height: 44px;
  background: #e00a0f;
  border: 0;
  border-radius: 0;
  text-align: center;
}
.search-holder button[type=submit] span {
  display: block;
  padding: 0;
  position: relative;
  line-height: 24px;
}
.search-holder button[type=submit] span:after {
  content: "\f002";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}
.search-holder button[type=submit]:hover {
  opacity: 0.85;
}
.search-holder .search-input {
  width: 100%;
  background: none;
  padding: 12px;
  color: #000;
  background: #dadad9;
  border: 0;
  height: 44px;
  outline: none;
}
.search-holder .input-holder {
  overflow: hidden;
}

.breadcrumb {
  background: transparent;
  padding: 10px 15px;
}
.breadcrumb ul li {
  display: inline-block;
  vertical-align: top;
  padding: 0 4px;
}
.breadcrumb ul li a {
  color: #000;
  text-decoration: none;
}
.breadcrumb ul li a:hover {
  text-decoration: underline;
}
.breadcrumb ul li.active {
  color: #e00a0f;
}
.breadcrumb ul li.active a {
  color: #e00a0f;
}
.breadcrumb ul li.active a:hover {
  text-decoration: none;
}
.breadcrumb ul li + li:before {
  color: #484747;
  content: "\f101";
  font-family: "Font Awesome 5 Pro";
  margin: 0 10px 1px 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 900;
}

.header-part {
  display: none;
}
.header-part .sub-nav {
  background: #fff;
  border-bottom: 1px solid #dadad9;
  padding: 0;
  margin-bottom: 30px;
}
.header-part .sub-nav ul {
  padding: 15px 0;
  margin: 0;
  text-transform: uppercase;
  margin: 0 -17px;
}
.header-part .sub-nav ul li {
  padding: 0 16px;
  display: inline-block;
  vertical-align: top;
}
.header-part .sub-nav ul li a {
  color: #333;
}
.header-part .sub-nav ul li a:hover {
  opacity: 0.8;
}
.header-part .sub-nav .opener {
  color: #000;
  border: 1px solid #dadad9;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  display: none;
}
.header-part .sub-nav .opener:hover {
  opacity: 0.8;
}
.header-part .sub-nav .opener span {
  display: block;
  position: relative;
  padding: 0 20px;
}
.header-part .sub-nav .opener span:after {
  content: "";
  position: absolute;
  right: 0;
  top: 7px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000;
  transition: transform 0.3s;
}
.header-part .sub-nav .opener.open span:after {
  transform: rotate(180deg);
}

.follow-box {
  text-align: center;
  overflow: hidden;
}
.follow-box .title {
  display: block;
  margin: 0 0 22px;
  text-transform: uppercase;
}
.follow-box .title a {
  color: #e00a0f;
  text-decoration: underline;
  text-transform: none;
}
.follow-box .title a:hover {
  text-decoration: none;
}
.follow-box .follow-us {
  background: #e00a0f;
  padding: 12px 20px;
  color: #fff;
  min-width: 273px;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}
.follow-box .follow-us span {
  position: relative;
  display: block;
  padding: 0 18px 0 0;
}
.follow-box .follow-us span:after {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: 0;
  top: 0;
}
.follow-box .follow-us em {
  font-style: normal;
  text-decoration: underline;
}
.follow-box .follow-us:hover em {
  text-decoration: none;
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1170px) {
  .heading-info h1 {
    font-size: 40px;
    line-height: 42px;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .header-part .sub-nav .opener {
    display: block;
  }
  .header-part .sub-nav ul {
    width: 100%;
    margin: 0;
    display: none;
  }
  .header-part .sub-nav ul li {
    width: 100%;
    text-align: center;
  }
}