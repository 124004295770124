
.img-strip {
	position: relative;
	margin-bottom:40px;
	img {
		width:100%;
	}
	h2 {
		position:absolute;
		top:45px;
		left:0;
		line-height: 24px;
		padding:10px;
		font-size: 3rem;
		font-weight: 600;
		color:$rbl-dark_blue;
		padding-left: 80px;
		display:none;
	}
}

.content-box {
	width:100%;
	// max-width:940px;
	margin:0 auto;
	padding:0 80px;
}

.gMap {
	margin:0 auto;
}

.related-links-container {
	padding:30px 0;
	border:solid 1px $stone;
	border-width: 1px 0;
	ul {
		padding:0;
		li {
			list-style: none;
			display: block;
			margin-bottom: 15px;
		}
	}
}

ul.ticker {
	display:none;
	/* &:first-of-type {
		display: block;
	} */
}

.gallery-thumb {
    float: left;
    text-align: center;
    width: 170px;
    margin: 0 15px 10px 0;
}


@media only screen and (min-width : 680px) {
	.img-strip {
		h2 {
			font-size: 3rem;
			top: 60px;
		}
	}
}

@media only screen and (min-width : 768px) {
	.img-strip {
		img{
			float:left;
			position:static;
			margin:0;
		}
		h2 {
			top: 90px;
			display: block;
		}

	}
}


@media only screen and (min-width : 1080px) {
	.img-strip {
		h2 {
			top:130px;
			font-size: 4.2rem;
		}
	}
}

@media only screen and (min-width : 1170px) {
	.img-strip {
		h2 {
			font-size: 6.4rem;
			top: 90px;
		}
	}
}