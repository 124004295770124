

//@extend-elements
%clearfix_pseudo {
	content: " ";
	display: block;
	clear: both;
}

%ul_reset {
	margin: 0;
	padding: 0;
	list-style: none;
}

%sup_sub {
	font-size: 75%;	
	position: relative;
	vertical-align: baseline;
}

// RESET 
html {
	height: 100%;
	box-sizing: border-box;
	&.no-footer-bottom {
		height: auto;
	}
}
* {
	box-sizing: inherit;
	max-height: 1000000px;
	&:before {
		box-sizing: inherit;
	}
	&:after {
		box-sizing: inherit;
	}
}
body {
	margin: 0;
	color: $black;
	background: $white;
	// font: 18px/24px $futura;
	min-width: 320px;
}
article, aside, details, figcaption, figure, footer, header, main, nav, section, summary {
	&.content-box {
		h2 {
			margin-bottom: 10px;
		}
	}
	display: block;
}

footer {
	a {
		color: $rbl-dark-blue;
	}
}
.clearfix {
	clear: both;
}
img {
	border-style: none;
	max-width: 100%;
	height: auto;
	max-height: none;
}
a {
	text-decoration: none;
	color:$red;
	&:hover {
		text-decoration: none;
	}
}

input[type="search"] {
	max-width:720px;
}

input:not([type="radio"]):not([type="checkbox"]):not([type="search"]), textarea, select {	
	// font: 100% $futura;
	vertical-align: middle;
	width:100%;
	-webkit-appearance:none;
	&:not(#surprise_amountb, #surprise_amounta, .btn, .link, .a-select, .a-input) {		
		color: $black !important;
	}
	// &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	//     transition:color 0.3s;
	// 	&
	//     color:    $charcoal_light;
	// }
	// &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	//    transition:color 0.3s;
	//    color:    $charcoal_light;
	//    opacity:  1;
	// }
	// &::-moz-placeholder { /* Mozilla Firefox 19+ */
	//    transition:color 0.3s;
	//    color:    $charcoal_light;
	//    opacity:  1;
	// }
	// &:-ms-input-placeholder { /* Internet Explorer 10-11 */
	//    transition:color 0.3s;
	//    color:    $charcoal_light;
	// }
	// &:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
	//   transition:color 0.3s;
	//   color:    $charcoal_light;
	// }
	&.gsc-search-button {
		color:$white;
		background:$red;
		border:0;
	}
}
label {
	display:inline-block;
	line-height:24px;
	padding-top:10px;
}
form, fieldset {
	
	margin: 0;
	padding: 0;
	border-style: none;
}
button::-moz-focus-inner {
	border: 0;
	padding: 0;
	padding: 0;
	border: 0;
}
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
button {
	cursor: pointer;
}
input[type="button"], input[type="reset"], input[type="file"], input[type="submit"] {
	cursor: pointer;
}
input[type="text"], input[type="tel"], input[type="email"], input[type="url"], input[type="password"], input[type="number"], input[type="phone"], input[type="date"] {
	margin: 0;
	padding: 0 15px;
	height:44px;
	line-height: 44px;
	width:100%;
	margin-bottom:30px;
	&:focus {
		border-color: $stone;
	}
}
.icheck-item {
	margin-bottom:20px !important;
}
input[type="number"], input[type="date"] {
	//padding-right:0;
	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button { 
	  -webkit-appearance: none; 
	  margin: 0; 
	}
}
input[type="number"] {
    -moz-appearance:textfield;
}
input#recaptcha_response_field {
	height:auto;
	line-height:initial;
	background:$white;
	padding:0 5px;
}
.field-validation-error {
	display:block;
	margin-bottom:5px;
	color:$red;
}
textarea {
	overflow: auto;
	resize: vertical;
	vertical-align: top;
	margin-bottom:30px;
	padding: 10px 15px;
	&:focus {
		border-color: $stone;
	}
}
input[type="checkbox"], input[type="radio"] {
	padding: 0;
}


#postcode_lookup_field {
	button, select {
		margin-bottom:30px;
	}
	button {
		max-width:273px;
	}
}

h1, h2, h3, h4, h5, h6 {	
	color: $rbl-dark_blue;
}

// h1, h2, h3, h4, h5, h6 {		
// 	font-family: $futura;
// 	line-height: 1.2em;
// 	margin: 0 0 1.2em;
// 	a {
// 		color:$black;
// 	}
// }
// h1 {
// 	font-size: 40px;
// 	font-weight: 400;
// 	margin: 0 0 18px;
// 	word-spacing: 0;
// 	color: $mine_shaft;
// 	text-transform: uppercase;
// }
// h2 {
// 	font-size: 24px;
// 	font-weight: 500;
// 	margin-bottom:15px;
// }
// This is replaced with essential css from main site
// h3, h4 h5, h6 { 
// 	font-size: 22px;
// 	font-weight: 500;
// 	text-transform: uppercase;
// 	margin-bottom:15px;
// }
h4 {
	font-weight:600;
}
p {
	margin: 0 0 1.2em;
}
q {
	quotes: none;
	&:before {
		content: "";
		content: none;
	}
	&:after {
		content: "";
		content: none;
	}
}
sub {
	@extend %sup_sub;
	bottom: -.25em;
}
sup {
	@extend %sup_sub;
	top: -.5em;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
	width:100%;
	thead {
		th {
			text-align: left;
			border-bottom:2px solid $grey;
			padding:15px;
		}
	}
	tbody {
		td {
			text-align: left;
			padding:15px;
		}
	}
}

i {
	font-family: $icon;
	font-style:normal;
}

i span {
    overflow: hidden;
    display: block;
    width: 0;
    height:0;
}

blockquote {
	font-size: 28px;
	line-height: 40px;
	text-align: center;
	margin-top:0;
}


input, textarea, select {	
	background:$grey;
	border:0;
	color:$black;
}

xmp { 
	white-space: pre-wrap; 
}

.spacer {
	display:block;
	clear:both;
	float:none;
	height:30px;
}

.recaptchatable #recaptcha_response_field {
	border:0;
}

.error {
	border:solid 1px $red;
	padding:15px;
	color:$red;
	background:rgba($red, 0.2);
}


//MAIN

#main, #header {	
	&:after {
		@extend %clearfix_pseudo;
	}
}

#main {
	background: $white;
	overflow: hidden;
	padding-top:148px;
}


.container-fluid, .container, .sub-container {
	max-width:940px;
	margin: 0 auto;
}

.mob-blackout {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	right:0;
	bottom:0;
	background:$black;
	display:none;
	opacity:0.7;
}

.link, button.link, button {
	color: $white;
	display: block;
	background: $red;
	padding: 10px 5px;
	margin: 0 0 3px;
	text-align: center;
	border-radius: 3px;
	width:100%;
	border:0;
	font-size:18px;
	line-height:24px;
	font-family:$futura;
	transition:all 0.2s;
// 	&:hover {
// 		background: darken($red, 7%);
// 	}
}

.main-home {
	border-top: 0 !important;
}
#wrapper {
	position: relative;
	// overflow: hidden;
	width: 100%;
	height: 100%;
	// margin-top:150px;
	&.main-wrapper {
		padding-left: 300px;
	}
}



.icheckbox_square-red, .iradio_square-red {
	height:27px !important;
	margin-right:10px !important;
	position:relative;
	top:10px;
}


.center {
	text-align:center;
}

// MODULES

.module, .block {
	margin:0 0 40px;
	&:after {
		content:"";
		clear:both;
		visibility:hidden;
		display: block;
	}
}

iframe {
	display:block;
	margin:0 auto;
}



.img-strip {
	position:relative;
	max-height:500px;
	overflow: hidden;
	.img-holder {
		overflow: hidden;
		img {
			min-width: 100%;
			max-height:none;
		}
	}
	.person-info {
		color:$white;

	}
}

.btn-default {
	position: relative;
	color: $black;
	overflow: hidden;
	display: inline-block;
	min-width:116px;
	padding: 0 64px 0 20px;
	background: $grey;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	height:44px;
	line-height:44px;
	border-radius:2px;
	border:0;
	max-width:100%;
	transition:background 0.2s;
	&:before {
		content: "\f105";
		position: absolute;
		right: 0;
		top: 0;
		font-family: $icon;
		color: $white;
		background: $red;
		display:block;
		width:44px;
		height:44px;
		line-height:44px;
		font-size:22px;
		text-align: center;
		font-weight:900;
	}
	&.back {
		padding: 0 0 0 44px;
		&:before {
			content: "\e809";
			right: auto;
			left: 0;
		}
	}
	// &:hover:before {
	// 	background: darken($red, 7%);
	// }
}
.hidden {
	display: none;
}

h2.heading {
	text-align: center;
	margin: 0 0 30px;
	display: block;
	padding: 0 15px;
	overflow: hidden;
	text-transform: uppercase;
	margin: 0 0 30px 0;
	span {
		position: relative;
		background: transparent;
		padding: 0 15px;
		display: inline-block;
		vertical-align: top;
		br {
			display: none;
		}
		&:before, &:after {				
			content: "";
			background: $stone;
			height: 1px;
			width: 9999px;
			position: absolute;
			right: 100%;
			top: 50%;
		} 
		&:after {
			right: auto;
			left: 100%;
		}
	}
}
.grid-item-link {
	text-align: center;
	color:$black;
	padding-bottom:30px;
	display:block;
	span:not(.logo-container) {
		display: block;
		line-height:30px;
		margin-top:10px;
		text-transform: uppercase;
		font-weight:500;
	}
	img {
		margin-bottom:5px;
		width:100%;
	}
	&:hover {
		span:after {
			opacity: 0.75;
		}
	}
}
.grid-title {
	text-transform: uppercase;
	font-weight:500;
	&:after {
		    display: inline-block;
		    margin-left: 10px;
		    content: "\f105";
		    color: #fff;
		    left: 0;
		    background: #e00a0f;
		    -webkit-transition: opacity 0.2s;
		    transition: left .2s;
		    position: relative;
		    border-radius: 1px;
		    font-size: 18px;
		    width: 26px;
		    font-family: "Font Awesome 5 Pro";
		    font-weight:900;
		    line-height:26px;
	}
}
.grid-date {
	color:$stone;
}
.head-part {
	padding: 40px 0;
	&.add {
		padding-top: 0;
		.breadcrumb {
			background: none;
		}
	}
}

// body content
.header {
	padding-bottom: 25px;
	p {
		margin: 0;
	}
}


/* Medium Devices, Desktops */
@media only screen and (max-width : 1170px) {

	


	#main {
		padding-top:44px;
	}

	.header-btns {
		display: block;
	}



	#wrapper.main-wrapper {
	    padding-left: 0;
		transition:padding-left 0.3s;
	}

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {


	.img-strip {
		max-height:200px;
	}

	input, textarea, select, .select2 {	
		margin-bottom:15px;
	}

	
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {


	.block-holder .masonry-col, .block-holder .col {
		width:100%;
	}

	.navigation {
		overflow:auto;
	}
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {
	.navigation {
		width:260px;
	}
	.nav-opener {
		&.open {
			left:260px;
		}
	}
}

@media print { 
 /* All your print styles go here */
 #header, #footer, .navigation { 
 	display: none !important;
} 

	#wrapper.main-wrapper {
	    padding-left: 0 !important;
	}

	.container-fluid {
		width:100% !important;
		max-width: 100% !important;
	}
}