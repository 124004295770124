.heading-info {
	text-align:center;
	padding-top:20px;
	h1 {
		font-size:44px;
		line-height:52px;
		span {			
			display:block;
			font-size:36px;
			line-height:40px;
		}
	}
	p {
		margin-bottom:0;
	}
}

.search-holder {
	background: $white;
	width: 100%;
	margin: 0 auto;
	position:relative;
	&:after {
		@extend %clearfix_pseudo;
	}
	button[type="submit"] {
		color: $white;
		float: right;
		width: 44px;
		height: 44px;
		background: $red;
		border: 0;
		border-radius:0;
		text-align: center;
		span {
			display: block;
			padding:0;
			position: relative;
			line-height: 24px;
			&:after {
				content: "\f002";
				font-family: $icon;
				font-weight: 900;
			}
		}
		&:hover {
			opacity: .85;
		}
	}
	.search-input {		
		width: 100%;
		background: none;
		padding: 12px;
		color: $black;
		background:$grey;
		border: 0;
		height: 44px;
		outline: none;
	}
	.input-holder {
		overflow:hidden;
	}
}
.breadcrumb {
	background: transparent;
	padding: 10px 15px;
	ul {
		@extend %ul_reset;
		&:after {
			@extend %clearfix_pseudo;
		}
		li {
			display: inline-block;
			vertical-align: top;
			padding: 0 4px;
			a {
				color: $black;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			&.active {
				color:$red;
				a {
					color:$red;
					&:hover {
						text-decoration:none;
					}
				}
			}
			+ li:before {
				color: $charcoal;
				content: "\f101";
				font-family: $icon;
				margin: 0 10px 1px 0;
				display: inline-block;
				font-size:14px;
				font-weight:900;
			}
		}
	}
}
.header-part { 
	display:none;
	.sub-nav {
		background: $white;
		border-bottom: 1px solid $grey;
		padding: 0;
		margin-bottom:30px;
		ul {
			@extend %ul_reset;
			padding: 15px 0;
			margin:0;			
			text-transform: uppercase;
			margin: 0 -17px;
			li {
				padding: 0 16px;
				display: inline-block;
				vertical-align: top;
				a {
					color: $mine_shaft;
					&:hover {
						opacity: .80;
					}
				}
			}
		}
		.opener {
			color: $black;
			border: 1px solid $grey;
			padding: 10px;
			text-align: center;
			text-transform: uppercase;
			display:none;
			&:hover {
				opacity: .80;
			}
			span {
				display: block;
				position: relative;
				padding: 0 20px;
				&:after {
					content: "";
					position: absolute;
					right: 0;
					top: 7px;
					width: 0;
					height: 0;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-top: 8px solid $black;
					transition:transform 0.3s;
				}
			}
			&.open {
				span:after {
					transform:rotate(180deg);
				}
			}
		}
	}
}


.follow-box {
	text-align: center;
	overflow: hidden;
	.title {
		display: block;
		margin: 0 0 22px;
		text-transform: uppercase;
		a {
			color: $red;
			text-decoration: underline;
			text-transform: none;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.follow-us {
		background: $red;
		padding: 12px 20px;
		color: $white;
		min-width: 273px;
		text-align: left;
		display: inline-block;
		vertical-align: top;
		span {
			position: relative;
			display: block;
			padding: 0 18px 0 0;
			&:after {
				content: "\f105";
				font-family: $icon;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		em {
			font-style: normal;
			text-decoration: underline;
		}
		&:hover em {
			text-decoration: none;
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1170px) {
	.heading-info h1 {
		font-size:40px;
		line-height:42px;
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

	.header-part {
		.sub-nav {
			.opener {
				display:block;
			}
			ul {
				width:100%;
				margin:0;
				display: none;
				li {
					width:100%;
					text-align: center;
				}
			}
		}
	}    
}