#footer {
	width: 100%;
	background: $white;
	padding-top:20px;
}
.footer-logo {
	max-width:120px;
	max-height:60px;
	margin:0 auto 20px auto;
}
.no-footer-bottom {
	body {
		height: auto;
	}
	#wrapper {
		height: auto;
		overflow: hidden;
	}
	#footer {
		display: block;
		height: auto;
	}
}
.socials {
	text-align: center;
	.live-on {
		display: inline-block;
		vertical-align: top;
		margin-bottom:20px;
		.title {
			text-transform: uppercase;
			display: inline-block;
			vertical-align: top;
			margin: -5px 0 0;    
			font-size: 40px;
			font-weight: 400;
			line-height: 36px;
		}
		.legion-logo {
			padding: 0 0 0 5px;
			display: inline-block;
			vertical-align: top;
		}
	}
}
.socials-holder {
	overflow: hidden;
	margin: 0 0 45px;
}

.socials-list {
	@extend %ul_reset;
	padding: 0 5px;
	position: relative;
	display: inline-block;
	vertical-align: top;
	&:before {
		right: auto;
		left: 100%;
	}
	li {
		display: inline-block;
		vertical-align: top;
		padding: 0 7px;
		a {
			color: $white;
		    display: block;
		    font-size: 20px;
		    line-height: 44px;
		    height: 44px;
		    width: 44px;
		    background: $charcoal;
		    border-radius: 2px;
		    position: relative;
			&:hover {
				opacity: .80;
			}
			i {
				position: absolute;
				top: 50%;
				left: 50%;
				transform:translate(-50%,-50%);
			}
		}
	}
}
.join-the-legion {
	background: $mine_shaft;
	padding: 15px;
	text-align: center;
}


.copyright {
	background: $charcoal;
	color:$white;
	text-align: center;
	padding:30px;
	font-size:14px;
	line-height:24px;
	.tel {
		display:block;
		margin-bottom: 15px;
	}
	p {
		margin: 0;
	}
		a {
			color: $white;
			&:hover {
				opacity: .75;
			}
		}
}


/* Medium Devices, Desktops */
@media only screen and (max-width : 1170px) {

	.socials-list {
		li {
			margin-bottom: 15px;
		}
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	.footer-logo {
		margin:0 auto 10px;
	}
}
